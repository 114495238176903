var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tooltip, Stack } from '@mui/material';
// components
import DownloadButton from './DownloadButton';
// utils
import { fileData, fileFormat, fileThumb } from './utils';
export default function FileThumbnail(_a) {
    var file = _a.file, tooltip = _a.tooltip, imageView = _a.imageView, onDownload = _a.onDownload, sx = _a.sx, imgSx = _a.imgSx;
    var _b = fileData(file), _c = _b.name, name = _c === void 0 ? '' : _c, _d = _b.path, path = _d === void 0 ? '' : _d, _e = _b.preview, preview = _e === void 0 ? '' : _e;
    var format = fileFormat(path || preview);
    var renderContent = format === 'image' && imageView ? (_jsx(Box, { component: "img", src: preview, sx: __assign({ width: 1, height: 1, flexShrink: 0, objectFit: 'cover' }, imgSx) })) : (_jsx(_Fragment, { children: fileThumb(format) }));
    if (tooltip) {
        return (_jsx(Tooltip, __assign({ title: name }, { children: _jsxs(Stack, __assign({ flexShrink: 0, component: "span", alignItems: "center", justifyContent: "center", sx: {
                    width: 'fit-content',
                    height: 'inherit',
                } }, { children: [renderContent, onDownload && _jsx(DownloadButton, { onDownload: onDownload })] })) })));
    }
    return (_jsxs(_Fragment, { children: [renderContent, onDownload && _jsx(DownloadButton, { onDownload: onDownload })] }));
}
